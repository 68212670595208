import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import makeRequest from '../../helpers/make-request'
import WorkWithUsView from './work-with-us-view'
import { useTranslation } from 'react-i18next'

const WorkWithusController = () => {
  const [loading, setLoading] = useState(true)
  const [dataWorkVhitUs, setDataWorkVhitUs] = useState([])
  const [isBeingSent, setIsBeingSent] = useState(false)
  const { i18n } = useTranslation();

  // catch with useEffect so the data will be contained
  const getDataWorkVhitUs = async () => {
    setLoading(true)
    const fields = 'id,title,slug,acf'
    const headers = {
      'Content-Type': 'application/json',
    }

    // fetch the data with makerequest
    makeRequest({
      headers,
      endPoint: 'pages',
      params: {
        slug: 'work-with-us',
        fields,
        lang: i18n.language
      },
    }).then((resp) => {
      setDataWorkVhitUs(resp.data[0])
      setLoading(false)
    })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  const onSubmit = (values) => {
    setIsBeingSent(true)
    const headers = {
      'Content-Type': 'application/form-data',
    }
    const keys = Object.keys(values)
    const bodyFormData = new FormData()
    keys.forEach((key) => {
      bodyFormData.append(key, values[key])
    })

    if (values.files) {
      values.files.forEach((item) => {
        bodyFormData.append('file[]', item)
      })
    }

    makeRequest({
      headers,
      urlString: `${process.env.GATSBY_API_URL_CONTACT}contact-form-7/v1/contact-forms/382/feedback`,
      method: 'POST',
      data: bodyFormData,
    }).then((resp) => {
      if (resp.data.status === 'mail_sent') {
        navigate('/thank-you/')
        setIsBeingSent(false)
      } else {
        setIsBeingSent(false)
      }
    }).catch((err) => console.error('ERROR', err))
  }

  useEffect(() => {
    getDataWorkVhitUs()
  }, [])

  const viewProps = {
    data: dataWorkVhitUs,
    loading,
    isBeingSent,
    onSubmit,
  }
  return (
    <WorkWithUsView {...viewProps} />
  )
}

export default WorkWithusController
