import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { useTranslation, Trans } from 'react-i18next'
import {
  Seo,
  Spinner,
  Tagline,
} from '../../components'
import { Form } from '../../components/form'
import { useSettings } from '../../hooks/use-settings'
import './work-with-us-styles.scss'
import GradientBanner from '../../components/gradient-banner'
import contactFormSchema from './validation-schema'
import IconEmail from '../../assets/icons/seasongroup_icons_email.svg'
import IconPhone from '../../assets/icons/seasongroup_icons_phone.svg'

const WorkWithUsView = ({
  data,
  loading,
  onSubmit,
  isBeingSent,
}) => {
  const { t, i18n } = useTranslation()
  const [formFields, setFormFields] = useState([])
  const [industriesOption, setIndustriesOptions] = useState([])
  const { menu } = useSettings()
  const initialValues = {
    purpose: '',
    'first-name': '',
    'last-name': '',
    'company-name': '',
    industry: '',
    location: '',
    role: '',
    email: '',
    message: '',
    file: '',
    accept: false,
  }
  useEffect(() => {
    const fields = [
      {
        label: t('contact.fields.question.label'),
        placeholder: t('contact.fields.question.placeholder'),
        formType: 'select',
        name: 'purpose',
        type: 'text',
        class: 'col-12 field__container',
        options: [
          {
            value: t('contact.fields.question.options.0'),
            label: t('contact.fields.question.options.0'),
          },
          {
            value: t('contact.fields.question.options.1'),
            label: t('contact.fields.question.options.1'),
          },
          {
            value: t('contact.fields.question.options.2'),
            label: t('contact.fields.question.options.2'),
          },
          {
            value: t('contact.fields.question.options.3'),
            label: t('contact.fields.question.options.3'),
          },
          {
            value: t('contact.fields.question.options.4'),
            label: t('contact.fields.question.options.4'),
          },
        ],
      },
      {
        label: t('contact.fields.firstName'),
        formType: 'field',
        name: 'first-name',
        type: 'text',
        class: 'col-md-6 col-12 field__container',
      },
      {
        label: t('contact.fields.lastName'),
        formType: 'field',
        name: 'last-name',
        type: 'text',
        class: 'col-md-6 col-12 field__container',
      },
      {
        label: t('contact.fields.company'),
        formType: 'field',
        name: 'company-name',
        type: 'text',
        class: 'col-12 field__container',
      },
      {
        label: t('contact.fields.industry.label'),
        placeholder: t('contact.fields.industry.placeholder'),
        formType: 'select',
        name: 'industry',
        type: 'text',
        class: 'col-12 col-md-6 field__container',
        options: industriesOption,
      },
      {
        label: t('contact.fields.location'),
        formType: 'field',
        name: 'location',
        type: 'text',
        class: 'col-12 col-md-6 field__container',
      },
      {
        label: t('contact.fields.role.label'),
        placeholder: t('contact.fields.role.placeholder'),
        formType: 'select',
        name: 'role',
        type: 'text',
        class: 'col-12 col-md-6 field__container',
        options: [
          {
            value: t('contact.fields.role.options.0'),
            label: t('contact.fields.role.options.0'),
          },
          {
            value: t('contact.fields.role.options.1'),
            label: t('contact.fields.role.options.1'),
          },
          {
            value: t('contact.fields.role.options.2'),
            label: t('contact.fields.role.options.2'),
          },
          {
            value: t('contact.fields.role.options.3'),
            label: t('contact.fields.role.options.3'),
          },
          {
            value: t('contact.fields.role.options.4'),
            label: t('contact.fields.role.options.4'),
          },
          {
            value: t('contact.fields.role.options.5'),
            label: t('contact.fields.role.options.5'),
          },
        ],
      },
      {
        label: t('contact.fields.email'),
        formType: 'field',
        name: 'email',
        type: 'text',
        class: 'col-12 col-md-6 field__container',
      },
      {
        label: t('contact.fields.message'),
        formType: 'textarea',
        name: 'message',
        row: '10',
        type: 'textarea',
        class: 'col-12 field__container',
      },
      {
        label: t('contact.fields.files.label'),
        formType: 'drop',
        name: 'file',
        type: 'textarea',
        class: 'col-12 field__container',
      },
      {

        formType: 'field',
        name: 'accept',
        type: 'checkbox',
        class: 'col-12 field__checkbox',
        comp: () => (
          <Trans i18nKey='contact.fields.accept'>
            I accept and have read the <Link to="/terms-and-conditions/">{{ terms: i18n.language === 'en' ? 'Terms & Conditions' : '本条款及细则' }}</Link>and the <Link to="/privacy-policy/">{{ policy: i18n.language === 'en' ? 'Privacy policy.' : '私隐政策。' }}</Link>.
          </Trans>

        ),
      },
    ]
    setFormFields(fields)
  }, [industriesOption])

  useEffect(() => {
    if (menu.length) {
      const industries = _.head(_.filter(menu, { link: { url: 'industries' } }))

      const optionsDropdown = _.reduce(industries?.sub_nav, (result, value, key) => {
        result.push({
          value: value.link.text,
          label: value.link.text,
        })
        return result
      }, [{
        value: null,
        label: 'All',
      }])
      setIndustriesOptions(optionsDropdown)
    }
  }, [menu])

  if (loading) {
    return <Spinner loading={loading} />
  }

  return (
    <>
      <div className="workWithUs__container">
        <Seo title="Season Group: Work with us" />
        <div className='workWithUs__dots'></div>
        {/* <h1>{_.get(contactForm, { title: 'Let\'s be partners' })}</h1> */}
        <div className="workWithUs__title">
          <Tagline text={t('tagline.services')} />
          <h1 className="page-title">
            <Trans i18nKey='contact.heroTitle'>
              Let's be<span>{{ partners: i18n.language !== 'en' ? '伙伴' : 'partners' }}</span>
            </Trans>
          </h1>
        </div>
        <div className="row workWithUs__info">
          <div className="col-12 col-md-6">
            <p>{_.get(data, 'acf.rows[0].copy')}</p>
          </div>
          <div className="col-12 col-md-6">
            {_.map(_.get(data, 'acf.rows[0].contacts'), (item, index) => (
              <div className="workWithUs__info__items">
                {index === 0 ? <IconPhone width="17" fill="#018AFF"/> : <IconEmail width="17" fill="#018AFF" />}
                <p key={index}>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="row workWithUs__list">
          <div className="col-12">
            <h4>{_.get(data, 'acf.rows[0].result_copy_title')}</h4>
            <div className="rich-text" dangerouslySetInnerHTML={{ __html: _.get(data, 'acf.rows[0].result_copy') }} />
          </div>
        </div>
        <div className="col-12 col-sm-8">
          <div className="workWithUs__form">
            <Form
              t={t}
              isBeingSent={isBeingSent}
              validationSchema={contactFormSchema}
              initialValues={initialValues}
              onHandelSubmit={onSubmit}
              formFields={formFields}
            />
          </div>
        </div>

      </div>
      <GradientBanner />
    </>
  )
}

export default WorkWithUsView
