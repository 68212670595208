import * as Yup from 'yup'

const contactFormSchema = Yup.object().shape({
  'first-name': Yup.string().required('Required'),
  'last-name': Yup.string().required('Required'),
  'company-name': Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  accept: Yup
    .bool()
    .oneOf([true], 'You need to accept the terms and conditions'),

})

export default contactFormSchema
